//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins";

@import "theme/default";
@import "theme/material";
@import "theme/minimal";
@import "theme/modern";
@import "theme/creative";
@import "theme/interaction";

// Structure
@import "structure/topbar";
@import "structure/page-head";
@import "structure/footer";
@import "structure/vertical";
@import "structure/horizontal";
@import "structure/two-column";
@import "structure/layouts";

// Components
@import "components/avatar";
@import "components/accordion";
@import "components/helper";
@import "components/preloader";
@import "components/forms";
@import "components/demos";
@import "components/print";
@import "components/ribbons";
@import "components/toast";
@import "components/scrollspy";

// Bootstrap components
@import "components/root";
@import "components/reboot";
@import "components/alerts";
@import "components/badge";
@import "components/buttons";
@import "components/breadcrumb";
@import "components/card";
@import "components/dropdown";
@import "components/nav";
@import "components/table";
@import "components/modal";
@import "components/pagination";
@import "components/progress";
@import "components/popover";
@import "components/type";
@import "components/form-check";
@import "components/form-control";
@import "components/list-group";
@import "components/tooltip";
@import "components/colored-links";

// Plugins
@import "plugins/custom-scrollbar";
@import "plugins/prismjs";
@import "plugins/dropzone";
@import "plugins/swiper";
@import "plugins/form-input-spin";
@import "plugins/ckeditor";
@import "plugins/listjs";
@import "plugins/apexcharts";
@import "plugins/echart";
@import "plugins/google-map";
@import "plugins/autocomplete";
@import "plugins/vector-maps";
@import "plugins/leaflet-maps";
@import "plugins/fullcalendar";
@import "plugins/datatables";
@import "plugins/flatpicker";
@import "plugins/flag-input";
@import "plugins/range-slider";
@import "plugins/card";

//Apps 
@import "apps/email";
@import "apps/chat";
@import "apps/ecommerce";
@import "apps/file-manager";
@import "apps/learning";
@import "apps/real_estate";
@import "apps/invoice";

// Pages
@import "pages/authentication";
@import "pages/dashboard";
@import "pages/coming-soon";
@import "pages/timeline";
@import "pages/profile";
@import "pages/pricing";

.dashboardBtn {
    height: 43px;
}
.dashboardSwiper {
    // margin: 0 !important;
    .swiper-button-prev {
        left: 0;
        top: 28px;
    }
    .swiper-button-next {
        right: 0;
        top: 28px;
    }
}
.btn-back {
    background: $back;
    &:hover {
        background: $back;
        opacity: 0.9;
    }
}
.btn-lay {
    background: $lay;
    &:hover {
        background: $lay;
        opacity: 0.9;
    }
}
// .backBorder {
//     border-left: solid 3px $back;
// }
// .layBorder {
//     border-left: solid 3px $lay;
// }
.backRow {
    background: rgba($color: $back, $alpha: 0.65);
    &:nth-of-type(odd) > * {
        --tb-table-striped-bg: rgba(15, 17, 20, 0);
    }
}
.layRow {
    background: rgba($color: $lay, $alpha: 0.5);
    &:nth-of-type(odd) > * {
        --tb-table-striped-bg: rgba(15, 17, 20, 0);
    }
}
.textSuccess {
    color: $green-600;
}
.textBack {
    color: $back;
}
.textLay {
    color: $lay;
}
.bgBack {
    background-color: $back;
}
.bgLay {
    background-color: $lay;
}
.bgBackLight {
    background-color: $backLight;
}
.bgLayLight {
    background-color: $layLight;
}
.oddsSection {
    .oddBtn {
        margin-left: 3px;
        min-width: 50px;
        height: 38px;
        &.back {
            background: $back;
        }
        &.backLight {
            background: $backLight;
        }
        &.lay {
            background: $lay;
        }
        &.layLight {
            background: $layLight;
        }
    }
}
.videoPlayer {
    position: absolute;
    width: 460px;
    height: 251px;
    z-index: 9999;
    background: #eef0f7;
    text-align: center;
    cursor: all-scroll;
    @media screen and (max-width:767px) {
        width: 100%;
    }
}
.modal-video-close-btn {
    display: none;
}
.modal-video-movie-wrap {
    padding-bottom: 0 !important;
}
.modal-video-inner {
    width: 100% !important;
}
.cursor-pointer {
    cursor: pointer;
}
.e-caret-hide {
    &::after {
        display: none;
    }
}
.inplayBlink {
    font-weight: 600;
    animation: blinkBacklay 1s infinite ease-in; 
  }
  
  @keyframes blinkBacklay {
    0% {
      color: red;
    }
  
    50% {
      color: green;
    }
  
    100% {
      color: red;
    }
}
.userListTable {
    tr {
        th, td {
            &:last-child {
                padding-left: 0;
            }
        }
    }
}

.back_blink {
    animation: 1s sparkBack !important;
}
  
.lay_blink {
    animation: 1s sparkLay !important;
}
  
@keyframes sparkBack {
    0% {
      background-color: #72bbef;
    }
  
    50% {
      background-color: #f8e71c;
    }
    100% {
      background-color: #72bbef;
    }
}
@keyframes sparkLay {
    0% {
      background-color: #faa9ba;
    }
  
    50% {
      background-color: #f8e71c;
    }
    100% {
      background-color: #faa9ba;
    }
}

.accordion-header {
    .accordion-button {
        font-size: var(--#{$prefix}font-lg);
    }
}

.customSelect {
    // z-index: 9;
    > div {
        border-color: #dde1ef;
    }
}

.filterDropDown {
    left: -50px;
    top: 30px;
    &::before {
        content: "";
        position: absolute;
        left: 20px;
        top: -8px;
        display: inline-block;
        border: 7px solid transparent;
        border-bottom: 8px solid #ffffff;
        border-top: 0 none;
        width: 15px;
    }
}
.filterDropDownIp {
    // left: -50px;
    top: 30px;
    right: -8px;
    &::before {
        content: "";
        position: absolute;
        // left: 20px;
        top: -8px;
        display: inline-block;
        border: 7px solid transparent;
        border-bottom: 8px solid #ffffff;
        border-top: 0 none;
        width: 15px;
        right: 80px;
    }
}

// .table-responsive{
//     min-height: 200px;
// }

.match_bets{
    min-height: 200px;
}